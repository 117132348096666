import { ErrorResponse } from '../types/ErrorResponse.type';
import { isObjectWithKey } from './common';
import { AxiosError } from 'axios';

export function isApiError(data: unknown): data is ErrorResponse {
  return (
    isObjectWithKey(data) &&
    typeof data.statusCode === 'number' &&
    (typeof data.message === 'string' || Array.isArray(data.message))
  );
}

export function isAxiosError(data: unknown): data is AxiosError {
  return isObjectWithKey(data) && data.name === 'AxiosError';
}
