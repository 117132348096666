import { User, UserSchema } from '../schemas/User.schema';
import UserRole from '../types/UserRole.enum';
import Paths from './Paths';
import { useEffect, useState } from 'react';
import { redirect } from 'react-router-dom';

export enum StorageAuthKeys {
  ACCESS_TOKEN = 'at',
  USERNAME = 'username',
  USER_ROLE = 'role',
  ORG_HOST = 'orghost',
}
export const saveAuthData = (user: User) => {
  if (user.access_token) {
    localStorage.setItem(StorageAuthKeys.ACCESS_TOKEN, user.access_token);
  }
  if (user.username) {
    localStorage.setItem(StorageAuthKeys.USERNAME, user.username);
  }
  if (user.role) {
    localStorage.setItem(StorageAuthKeys.USER_ROLE, user.role);
  }
  if (user.organisation?.host) {
    localStorage.setItem(StorageAuthKeys.ORG_HOST, user.organisation.host);
  }
};

export const getAuthData = (): User | null => {
  const access_token = localStorage.getItem(StorageAuthKeys.ACCESS_TOKEN);
  const username = localStorage.getItem(StorageAuthKeys.USERNAME);
  const role = localStorage.getItem(StorageAuthKeys.USER_ROLE) as UserRole;
  const orgHost = localStorage.getItem(StorageAuthKeys.ORG_HOST);
  const storedUser: User = {
    access_token,
    username,
    role,
    organisation: { host: orgHost },
  };
  const parsedAuth = UserSchema.safeParse(storedUser);
  if (parsedAuth.success) return parsedAuth.data;
  return null;
};

export const clearAuthData = () => {
  Object.values(StorageAuthKeys).forEach((key) => {
    localStorage.removeItem(key);
  });
};

export function useAuth() {
  const [auth, setAuth] = useState<User | null>(null);

  useEffect(() => {
    const parsedAuth = UserSchema.safeParse(getAuthData());
    if (parsedAuth.success) {
      setAuth(parsedAuth.data);
    } else {
      redirect(Paths.SIGN_IN);
    }
  }, []);

  return auth;
}

export function useUserRole() {
  const user = useAuth();

  return {
    isClient: user?.role === UserRole.CLIENT,
    isOwnerOrSuperAdmin:
      user?.role === UserRole.OWNER || user?.role === UserRole.SUPER_ADMIN,
  };
}
