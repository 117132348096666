export const ApiLinks = {
  PROJECTS: {
    ALL: '/projects',
    PROJECT: '/projects/:projectId',
    CONFIG: '/projects/:projectId/config',
    EPICS: '/projects/:projectId/epics',
    TAGS: '/projects/:projectId/tags',
    USERS: '/projects/:projectId/users',
    UPDATE: '/sync/projects/:configId',
    RATES: {
      ALL: '/projects/:projectId/rates',
      RATE: '/projects/:projectId/rates/:rateId',
    },
    ACCESS: '/projects/:projectId/users/:userId',
  },
  ORGANISATIONS: {
    ALL: '/organisations',
  },
  CONFIG: {
    ALL: '/sync/configs',
    UPDATE: '/sync/configs/:key',
    SYNC_BY_KEY: '/sync/configs/search/:key',
  },
  FORECAST: {
    COMPONENTS_STATS: '/forecast/:projectId/stats',
  },
  AUTH: {
    LOGIN: '/auth/login',
  },
  USERS: {
    ALL: '/users',
    USER: '/users/:userId',
  },
  SYNC: {
    INIT: {
      NEW: 'sync/init/new',
    },
    TOKENS: {
      ALL: '/sync/tokens',
      TOKEN: '/sync/tokens/:tokenId',
    },
  },
} as const;
