import UserRole from '../types/UserRole.enum';
import { assert } from '../utils/assert';
import { getAuthData } from '../utils/authDataStorage';
import { LoaderFunction } from 'react-router-dom';

export const unauthorizeUserClient: LoaderFunction = () => {
  const session = getAuthData();
  const isNotRoleClient = session?.role !== UserRole.CLIENT;

  assert(isNotRoleClient, 'Error: You are not authorized to view this.');
  return session;
};

export const authorizeOwnerOrSuperAdmin: LoaderFunction = () => {
  const session = getAuthData();
  const isOwnerOrSuperAdmin =
    session?.role === UserRole.OWNER || session?.role === UserRole.SUPER_ADMIN;

  assert(isOwnerOrSuperAdmin, 'Error: You are not authorized to view this.');
  return session;
};
