import logo from '../../assets/images/logo.png';
import { FC } from 'react';

const Logo: FC = () => (
  <img
    alt="logo"
    src={logo}
    height="40"
    width="50"
    className="aspect-[50/40] max-w-[40px]  md:aspect-[50/40] md:max-w-[50px]"
  />
);

export default Logo;
