import Paths from '../utils/Paths';
import { getAuthData } from '../utils/authDataStorage';
import { LoaderFunction, redirect } from 'react-router-dom';

export const redirectLogin: LoaderFunction = () => {
  const session = getAuthData();
  if (!session?.access_token) return null;

  throw redirect(Paths.PROJECTS.INDEX);
};
