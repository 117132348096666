export const DynamicPaths = {
  PROJECT_ID: ':projectId',
  TOKEN_ID: ':tokenId',
  USER_ID: ':userId',
} as const;

const Paths = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  PROJECTS: {
    INDEX: '/projects',
    SYNC: 'sync',
  },
  PROJECT: {
    INDEX: `/projects/${DynamicPaths.PROJECT_ID}`,
    SPRINTS: 'sprints',
    CONFIG: {
      INDEX: 'config',
      BUDGET: 'budget',
      RATES: 'rates',
      USERS: 'users',
    },
    METRICS: 'metrics',
  },
  USERS: {
    INDEX: 'users',
    ADD: 'add',
    SYNC_TOKENS: 'sync-tokens',
    EDIT: `/users/${DynamicPaths.USER_ID}`,
  },
  SYNC_TOKENS: {
    INDEX: '/sync-tokens',
    ADD: 'add',
    EDIT: `/sync-tokens/${DynamicPaths.TOKEN_ID}`,
  },
  NOT_FOUND: '*',
} as const;

export const getProjectPath = (projectId: string) =>
  Paths.PROJECT.INDEX.replace(DynamicPaths.PROJECT_ID, projectId);

export const getUserPath = (userId: string) =>
  Paths.USERS.EDIT.replace(DynamicPaths.USER_ID, userId);

export const getSyncTokenPath = (tokenId: string) =>
  Paths.SYNC_TOKENS.EDIT.replace(DynamicPaths.TOKEN_ID, tokenId);

export default Paths;
