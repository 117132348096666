import { UserSchema } from '../schemas/User.schema';
import { Credentials } from '../types/Credentials.type';
import { ErrorResponse } from '../types/ErrorResponse.type';
import Paths from '../utils/Paths';
import { saveAuthData } from '../utils/authDataStorage';
import { isApiError } from '../validators/error';
import { getApiPath } from './getApiPath';
import handleRequestError from './handleRequestError';
import { apiClient, getErrorResponseData } from './utils';
import { isAxiosError } from 'axios';
import { ActionFunction, redirect } from 'react-router-dom';

export const signIn: ActionFunction = async ({ request }) => {
  const credentials: Credentials = await request.json();

  try {
    const response = await apiClient.post(
      getApiPath('AUTH.LOGIN', {}),
      credentials,
    );

    const user = UserSchema.parse(response.data);

    saveAuthData(user);
    return redirect(Paths.PROJECTS.INDEX);
  } catch (error) {
    handleRequestError(error);
    if (isAxiosError(error)) {
      const errorResponseData = getErrorResponseData(error);
      if (isApiError(errorResponseData)) {
        return errorResponseData;
      }
    }
    return {
      message: 'Something went wrong',
    } as ErrorResponse;
  }
};
